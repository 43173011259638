import React, { Component } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {activeAnimation} from '../../assets/js/keyframes';


export default class Carousel extends Component {
  constructor(props) {
    super(props);
    
  }

  componentDidMount(){
    activeAnimation()
  }
  render() {
    return (
      <section className='where-container'>
        <div className='images-slider-wrapper'>
          <Swiper 
            slidesPerView={"auto"}
            spaceBetween={7}
            className="mySwiper"
          >
            <SwiperSlide>
              <img draggable="false" src={require('../../assets/img/about-slider-1.jpg')} />
            </SwiperSlide>
            <SwiperSlide>
              <img draggable="false" src={require('../../assets/img/about-slider-2.jpg')} />
            </SwiperSlide>
            <SwiperSlide>
              <img draggable="false" src={require('../../assets/img/about-slider-3.jpg')} />
            </SwiperSlide>
            <SwiperSlide>
              <img draggable="false" src={require('../../assets/img/about-slider-4.jpg')} />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    )
  }
}
