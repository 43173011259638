import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { IconEmail,IconLocation } from '../../assets/icons/index';
import {activeAnimation} from '../../assets/js/keyframes';
import {Device} from '../../assets/constans/constans';
import { withTranslation } from 'react-i18next';
import { IconArrowBlueAnimation } from '../../assets/icons/index';

class Work extends Component {
  isResponsive = () => {
    return window.innerWidth > Device.MOBILE ? true : false
  }
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const {t, i18n} = this.props;
    const lestTalk = t('lestTalk', { returnObjects: true });
    return (
      <>
        <div className='page-header'>
          {this.isResponsive()?
            null
            :
            <Container>
            <div className='page-header-wrapper'>
              <Row className='text-wrapper'>
                <Col md={5} className='left-col'>
                  <div className='content-arrow-blue animationShow scaleCreated'>
                    <IconArrowBlueAnimation/>
                  </div>
                  <h1 className='animationShow slideRight' dangerouslySetInnerHTML={{__html: lestTalk.titleSlider}}>
                  </h1>
                </Col>
                <Col md={7} className='right-col' style={{paddingLeft:'0'}}>
                  <h2 className='animationShow animationText' style={{marginBottom:'20%'}} dangerouslySetInnerHTML={{__html: lestTalk.title_mb}}>
                  </h2>
                </Col>
              </Row>
            </div>
          </Container>
          }
          { this.isResponsive() ?   
           null   
            :
            <div className='image-wrapper animationShow slideTop'>
            <img src={require('../../assets/img/banner-lets-talk.png')} />
          </div>
          }   
        </div>
        <div className='page-content'>
          {this.isResponsive()?
          <Container>
          <Row className='text-wrapper'>
            <Col md={5} className='left-col'>
              {this.isResponsive() ?
                <>
                  <div className='content-arrow-blue animationShow scaleCreated'>
                    <IconArrowBlueAnimation/>
                  </div>
                  <h1 className='animationShow slideRight' dangerouslySetInnerHTML={{__html: lestTalk.titleSlider}}>

                  </h1>
                </>
                :
                null
              }
            </Col>
            <Col md={7} className='right-col' style={{paddingLeft:'0'}}>
              {this.isResponsive() ? 
                <h2 className='animationShow animationText' dangerouslySetInnerHTML={{__html: lestTalk.title_dt}}>
                </h2>
                :
                null
              }
            </Col>
          </Row>
          {this.isResponsive() ?
            <div className='image-wrapper animationShow slideTop'>
              <img src={require('../../assets/img/banner-lets-talk.png')} />
            </div>
              :
            null
          }  
        </Container> 
        :
        null

          }
          <Row className='info-work'>
            <Col md={5}>
              <IconEmail/>
              <p dangerouslySetInnerHTML={{__html: lestTalk.correo}}>
              {/* {lestTalk.correo} */}
              </p>
            </Col>
            <Col md={5}>
              <IconLocation/>
              <p>
                Avenida Paseo de la Republica <br className='no_desktop'/> 3195 - Of 603 <br className='no_desktop'/> San Isidro - Lima (Lima 27) <br className='no_mb'/> <br className='no_desktop'/>Perú
              </p>
            </Col>
            {/* <Col md={2}>
              <div><button className='btn btn-outline-white'>View of map</button></div>
            </Col> */}
          </Row>
        </div>
      </>
    )
  }
}
export default withTranslation()(Work)