import React, {  } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { IconFb, IconIg, IconIn } from '../../assets/icons/index';
import {Device} from '../../assets/constans/constans';
import {activeAnimation} from '../../assets/js/keyframes';
import { useTranslation } from 'react-i18next';

const slider = [
  {
    gif:'videos/video_1.mp4'
  },
  {
    gif:'videos/video_2.mp4'
  },
  {
    gif:'videos/video_3.mp4'
  },
];

const DELAY = 20000;

function Home() {
  const { t, i18n } = useTranslation();
  const home = t('home', { returnObjects: true });
  const correo = t('correo', { returnObjects: true });
  const [index, setIndex] = React.useState(0);
  const [isScroll, setIsScroll] = React.useState(false);
  const timeoutRef = React.useRef(null);

  const [onePermission, setOnePermission] = React.useState(true)
  const [directionScroll, setDirectionScroll] = React.useState(false)
  const [nextSliderAuto, setNextSliderAuto] = React.useState(true)
  const [valueCurrentScroll, setvalueCurrentScroll] = React.useState(1) //valor del contador (counterCurrentScroll), esto depende de la pantalla
  const [counterCurrentScroll, setCounterCurrentScroll] = React.useState(0) // contador que indica cuantas veces se movio la rueda
  const [isPermisionChangeSliderOption, setIsPermisionChangeSliderOption] = React.useState(false)
  const [isPermissionClean,setIsPermissionClean] = React.useState(true)
  const [isPermissionScrollCounter , setIsPermissionScrollCounter] = React.useState(false)
  const [isValidTransition,setIsValidTransition] = React.useState(true)

  let elementVideo = [];
  let headerBackground;
  var arrayInterval = [];

  function sliderHome(deltaY) {
    setIsScroll(true)
    if(isPermissionScrollCounter) return 
    setvalueCurrentScroll(deltaY)
    setDirectionScroll(valueCurrentScroll>0 ? false : true)
    if(counterCurrentScroll===0){
      setIsValidTransition(false)
      setTimeout(()=>{setIsValidTransition(true)},120)
    }
    elementVideo = document.querySelectorAll('.video-wrapper video');
    headerBackground = document.querySelector('.page-header-background');
    if(deltaY>0){
      if(counterCurrentScroll<0){
        setCounterCurrentScroll(0)
        //tiempo de espera cuando se regresa
        setIsPermissionScrollCounter(true)
        setTimeout(()=>{
          setIsPermissionScrollCounter(false)
        },600)
        return
      }
      if(counterCurrentScroll <10){
        setCounterCurrentScroll(counterCurrentScroll+1)
        if(index + 1 >= slider.length){
          // setIndex(0)
          elementVideo[0].style.display= 'block';
          elementVideo[0].style.zIndex= '2';
          elementVideo[index-1].style.display= 'none';
          elementVideo[index-1].style.zIndex= '2';
          headerBackground.style.backgroundColor = getColor(0)
        }else{
          if(index==0){
            elementVideo[slider.length-1].style.display= 'none';
            elementVideo[slider.length-1].style.zIndex= '2';
          }else{
            elementVideo[index-1].style.display= 'none';
            elementVideo[index-1].style.zIndex= '2';
          }
          elementVideo[index+1].style.display= 'block';
          elementVideo[index+1].style.zIndex= '2';
          headerBackground.style.backgroundColor = getColor(index+1)
        }
      }
    }else{
      if(counterCurrentScroll>0){
        setCounterCurrentScroll(0)
        //tiempo de espera cuando se regresa
        setIsPermissionScrollCounter(true)
        setTimeout(()=>{
          setIsPermissionScrollCounter(false)
        },600)
        return
      }
      if(counterCurrentScroll > -10){
        setCounterCurrentScroll(counterCurrentScroll-1)
        if (index - 1 < 0 ) {
          elementVideo[slider.length - 1].style.display= 'block';
          elementVideo[slider.length - 1].style.zIndex= '2';
          elementVideo[index+1].style.display= 'none';
          elementVideo[index+1].style.zIndex= '2';
          headerBackground.style.backgroundColor = getColor(slider.length - 1)
        }else{
          if(index===slider.length - 1){
            elementVideo[0].style.display= 'none';
            elementVideo[0].style.zIndex= '2';
          }else{
            elementVideo[index+1].style.display= 'none';
            elementVideo[index+1].style.zIndex= '2';
          }
          elementVideo[index-1].style.display= 'block';
          elementVideo[index-1].style.zIndex= '2';
          headerBackground.style.backgroundColor = getColor(index-1)
        }
      }
    }
    if(counterCurrentScroll<= -10 || counterCurrentScroll>=10){
      setIsPermissionScrollCounter(true)
      setIsValidTransition(false)
      setCounterCurrentScroll(0)
      elementVideo.forEach((e)=>{
        e.style.zIndex = '2';
      })
      if(counterCurrentScroll>= 10){
        if(index + 1 >= slider.length){
          setIndex(0)
          elementVideo[0].style.display= 'block';
          elementVideo[0].style.zIndex= '3';
        }else{
          setIndex(index+1)
          elementVideo[index+1].style.display= 'block';
          elementVideo[index+1].style.zIndex= '3';
        }
      }
      if(counterCurrentScroll<= -10){
        if (index - 1 < 0 ) {
          setIndex(slider.length - 1)
          elementVideo[slider.length - 1].style.display= 'block';
          elementVideo[slider.length - 1].style.zIndex= '3';
        }else{
          setIndex(index-1)
          elementVideo[index-1].style.display= 'block';
          elementVideo[index-1].style.zIndex= '3';
        }
      }
      cleanTextSlider()
      setTimeout(()=>{
        activeAnimation()
        addClassTextHome()
      },1200)
      setTimeout(()=>{
        setIsPermissionScrollCounter(false)
      },2000)
    }
  }

  function opacityValidText(){
    if(counterCurrentScroll===0) return 1
    if(valueCurrentScroll > 0){
      return 1-(counterCurrentScroll/2.5)
    }else{
      return 1-(-counterCurrentScroll/2.5)
    }
  }

  function cleanTextSlider(idx){
    if(isPermissionClean){
      var listadoSliderText= document.querySelectorAll('.main-slider li');
      listadoSliderText.forEach((e,position) =>{
        var childrens = Array.prototype.slice.call(e.children)
        var childrenChildrenH1 = Array.prototype.slice.call(childrens[1].children);
        if(e.classList.contains('animationTextHome')){
          if(idx) position > idx ? setDirectionScroll(false) : setDirectionScroll(true)
          e.classList.remove('animationTextHome')
            childrens[0].style.animation = 'none'
            childrens[0].style.setProperty('opacity',0,'important')
          childrenChildrenH1.forEach((a,index)=>{
              a.style.animation = 'none'
              a.style.setProperty('opacity',0,'important')
          })
        }
      })
    }
  }

  function validDireccionAnimation(idx){
    if(index <idx){
      setDirectionScroll(false)
      setvalueCurrentScroll(1)
    }
    else if(index >idx){
      setDirectionScroll(true)
      setvalueCurrentScroll(0)
    }
    else{
      setDirectionScroll(false)
      setvalueCurrentScroll(1)
    }
  }

  function changeSliderOption(DELAY,idx){
    if(isPermisionChangeSliderOption) return
    changeSlider(DELAY,idx)
  }
  function changeSlider(DELAY,idx,goValidDireccion){
    if(index === idx)return
    cleanTextSlider(idx)
    setIsPermisionChangeSliderOption(true)
    elementVideo = document.querySelectorAll('.video-wrapper video');
    headerBackground = document.querySelector('.page-header-background');
    elementVideo.forEach((e,position)=>{
      if(position==idx || position== index){
        e.style.zIndex= '2';
        e.style.display= 'block'
      }else{
        e.style.zIndex= '2';
        e.style.display= 'none'
      }
    })
    if(idx || idx===0){
      setNextSliderAuto(false)
      // displayZindex(false)
      setTimeout(()=>{
        activeAnimation()
      },200)
      validDireccionAnimation(idx)
      animationChangeSlider(false,goValidDireccion,idx)
      if(document.querySelector('.optionsDropdownMobile')){
        document.querySelector('.optionsDropdownMobile').style.backgroundColor = getColor(idx)
      }
      return
    }
    if(document.querySelector('.optionsDropdownMobile')){
      document.querySelector('.optionsDropdownMobile').style.backgroundColor = getColor(index+1)
    }
    // displayZindex(null)
    validDireccionAnimation(idx)
    animationChangeSlider(true,goValidDireccion,idx)
  }
  function animationChangeSlider(isActiveNext,goValidDireccion,idx){    
    setIsValidTransition(true)
    setIsPermissionScrollCounter(true)
    let listadoSlider= document.querySelector('.main-slider');
    let itemBackgroundHome = document.querySelector('.page-header-background-inner')
    let itemVideoHome = document.querySelector('.element-cover.loop-active')
    headerBackground.style.backgroundColor = getColor(idx)
    let statusDireccion
      if(idx === null){
        elementVideo[index].style.zIndex= '3';
        elementVideo[index].style.display= 'block';
        if(index + 1 >= slider.length){
          // setIndex(0)
          elementVideo[0].style.display= 'block';
          elementVideo[0].style.zIndex= '2';
          elementVideo[index-1].style.display= 'none';
          elementVideo[index-1].style.zIndex= '2';
          headerBackground.style.backgroundColor = getColor(0)
        }else{
          elementVideo[index+1].style.display= 'block';
          elementVideo[index+1].style.zIndex= '2';
          headerBackground.style.backgroundColor = getColor(index+1)
          if(index===0){
            elementVideo[slider.length - 1].style.display= 'none';
            elementVideo[slider.length - 1].style.zIndex= '2';
          }else{
            elementVideo[index-1].style.display= 'none';
            elementVideo[index-1].style.zIndex= '2';
          }
        }
      }
    for(let i = 0;i<=10;i++){
      setTimeout(()=>{
        if(goValidDireccion){
          if(index == slider.length-1) {
            setDirectionScroll(true)
            setvalueCurrentScroll(0)
            statusDireccion= true
          }
          else{
            setDirectionScroll(false)
            setvalueCurrentScroll(1)
            statusDireccion= false
          }
        }
        else if(index > idx){
          statusDireccion= true
        }
        else statusDireccion= false
        // los display de los videos 
        // debugger;
        if(statusDireccion){
          itemVideoHome.style.clipPath='polygon(0px '+`${'0'}`+'%, 100% '+`${'0'}`+'%, 100% '+`${100-(i*10)}`+'%, 0px '+`${100-(i*10)}`+'%)'
        }else{
          itemVideoHome.style.clipPath='polygon(0px '+`${(directionScroll ? '0' : (i*10))}`+'%, 100% '+`${(directionScroll ? '0' : (i*10))}`+'%, 100% '+`${(directionScroll ? 100-(i*10) : '100')}`+'%, 0px '+`${(directionScroll ? 100-(i*10) : '100')}`+'%)'
        }
        itemBackgroundHome.style.transform='scale(1,'+`${1-(i/10)}`+')'
        listadoSlider.style.opacity= 1-(i/2.5)
      }, 80* i)
    }
    setTimeout(()=>{
      setIsValidTransition(false)
      if(idx>=0 && idx !=null){
        elementVideo[idx].style.zIndex= '3';
        elementVideo[idx].style.display= 'block';
      }else{
        if(index + 1 >= slider.length){
          // setIndex(0)
          elementVideo[0].style.display= 'block';
          elementVideo[0].style.zIndex= '3';
          elementVideo[slider.length-1].style.display= 'block';
          elementVideo[slider.length-1].style.zIndex= '2';
        }else{
          elementVideo[index+1].style.display= 'block';
          elementVideo[index+1].style.zIndex= '3';
        }
      }
    },900)
    setTimeout(()=>{
      setCounterCurrentScroll(0)
      itemBackgroundHome.style.transform='scale(1,1)'
      setDirectionScroll(false)
      if(isActiveNext) {
        setIndex((prevIndex) => prevIndex === slider.length - 1 ? 0 : prevIndex + 1);
      }else 
      setIndex(idx)
      activeAnimation()
      setIsValidTransition(true)
      setIsPermissionScrollCounter(false)
      setIsPermisionChangeSliderOption(false)
      listadoSlider.style.opacity=1;
      addClassTextHome(idx)
    },1200)
  }

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() =>{
    if(onePermission){
      addClassTextHome()
      setOnePermission(false)
      if(document.querySelector('.optionsDropdownMobile') && index===0){
        document.querySelector('.optionsDropdownMobile').style.backgroundColor = getColor(0)
      }
    }
  })
  React.useEffect(() => {
    const nextSlide = () => {
      if(counterCurrentScroll===0){
        setDirectionScroll(false)
        if(nextSliderAuto)changeSlider(DELAY,null,true)
      }
    };

    if(!isScroll) {
      timeoutRef.current = setTimeout(nextSlide, DELAY);
    }

    return () => {
      resetTimeout();
    };
  }, [index]);

  React.useEffect(() => {
    if(isScroll) {
      const interval = setInterval(() => {
        setIsScroll(false);
        setCounterCurrentScroll(0)
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [isScroll]);

  function addClassTextHome(positionIndex){
    document.querySelectorAll('.video-wrapper video').forEach((e,p) =>{
      arrayInterval.push('positionIndex_'+p)
    })
    let elementH1Span = document.querySelector('.animationTextHome h1').children;
    let elementLiSpan = document.querySelector('.animationTextHome > span');
    let timeChangeSpan = (DELAY/elementH1Span.length);
    let totalSpan = document.querySelectorAll('.main-slider h1 span')
    let valueArray = positionIndex==null ? index:positionIndex;
    for(let counter in elementH1Span){
      arrayInterval[valueArray] = setTimeout(()=>{
        if(counter==0 ? true: elementH1Span[0].parentElement.parentElement.classList.contains('animationShow')){
          if((counter<elementH1Span.length)){
            if(counter!=0){
              elementH1Span[counter-1].style.animation = '1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter_back';
              elementH1Span[counter-1].style.opacity = 0;
            }
            totalSpan.forEach(element => {
              element.classList.remove('active');
              element.style.animation='1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter_back';
              element.style.opacity = 0;
            });
            if(counter==0){
              elementLiSpan.style.animation = '1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter';
              elementLiSpan.style.opacity = 1;
            }
            elementH1Span[counter].style.animation = '1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter';
            elementH1Span[counter].style.opacity = 1;
            if(counter ==elementH1Span.length){
              clearTimeout(arrayInterval[valueArray])
              return
            }
            counter += 1;
          }else{
            clearTimeout(arrayInterval[valueArray])
            counter=0;
          }
          
        }else{
          clearTimeout(arrayInterval[valueArray])
          counter=0;
        }
      },timeChangeSpan*counter)
    }
  }
  function getColor(paramIndex) {
    // #003979 el mas oscuro 
    // #004f9f el del medio
    // #0068b6 el mas claro
    switch(paramIndex) {
      case 0 :
        return'#003979'
      case 1 :
        return '#004f9f'
      case 2 :
        return '#0068b6'
      case 3 :
        return '#003979'
      default :
        return '#0068b6'
    }
  }

  function isResponsive(){
    return window.innerWidth > Device.MOBILE ? true : false
  }
    return (
      <div className="home-slider-wrapper" onWheel={(e) => sliderHome(e.deltaY)}>
        <div className='video-wrapper'>
          {isResponsive()?
            <div className='button-scroll'>Scroll</div>
            :
            null
          }
          <span className='background' />
          {slider.map((item,i) => (
            <video className={`element-cover loop${index === i ? " loop-active" : ""}`} autoPlay loop muted playsInline key={'slider_'+i}
            id={'slider_'+i}
            src={item.gif}
            style={{
              transition: isValidTransition ? '.1s all linear' : 'none',
              // zIndex:index === i ? 3 : posSliderZ_index(i),
              // display: index === i ? 'block' : 'none',
              // display: index === i ? 'block' : posSlider(i),
              opacity: 1,
              visibility: 'inherit',
              clipPath: index === i  ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' ??  directionScroll ? //////////////// cuando es verdadero es arriba y false cuando es para abajo
                'polygon(0px '+`${(directionScroll ? '0' : (counterCurrentScroll*10))}`+'%, 100% '+`${(directionScroll ? '0' : (counterCurrentScroll*10))}`+'%, 100% '+`${(directionScroll ? 100-(-counterCurrentScroll*10) : '100')}`+'%, 0px '+`${(directionScroll ? 100-(-counterCurrentScroll*10) : '100')}`+'%)'
                : 'polygon(0px '+`${(directionScroll ? '0' : (counterCurrentScroll*10))}`+'%, 100% '+`${(directionScroll ? '0' : (counterCurrentScroll*10))}`+'%, 100% '+`${(directionScroll ? 100-(-counterCurrentScroll*10) : '100')}`+'%, 0px '+`${(directionScroll ? 100-(-counterCurrentScroll*10) : '100')}`+'%)'
                : 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
            }} 
            />
          ))}
          <ul className="slider-bullets">
            {slider.map((item, idx) => (
              <li
                style={{cursor:idx===index ? 'initial' : 'pointer'}}
                key={idx}
                className={`${index === idx ? " active" : ""}`}
                onClick={() => {
                  changeSliderOption(DELAY,idx);
                }}
              >
                <span className='bullet'>
                  <span className='empty' style={{backgroundColor: isResponsive() ?  '#2958c2' : '#2958c2'}} />
                  <span className='full' style={{backgroundColor: isResponsive() ?  '#82afd9': 'white'}}/>
                </span>
              </li>
            ))}
          </ul>
        </div>
        <Container>
          <Row>
            {
            isResponsive() ? 
              <Col md={7}>
                
              </Col>
              :
              null
            }
            <Col md={5} className='right-col-wrapper'>
              {/* <span style={{color:'white'}}>{index}</span> */}
              <ul className='main-slider' style={{opacity:opacityValidText()}}>
                <li className={( index == 0? 'animationShow animationTextHome':'' )}>
                  <span className='number'>1.</span>
                  <h1 dangerouslySetInnerHTML={{__html: home.step1}}></h1>
                </li>
                <li className={( index == 1? 'animationShow animationTextHome':'' )}>
                  <span className='number'>2.</span>
                  <h1 dangerouslySetInnerHTML={{__html: home.step2}}></h1>
                </li>
                <li className={( index == 2? 'animationShow animationTextHome':'' )}>
                  <span className='number'>3.</span>
                  <h1 dangerouslySetInnerHTML={{__html: home.step3}}></h1>
                </li>
              </ul>
              <div className='email-wrapper'>
                <a href={'mailto:'+correo.correo}>{correo.correo}</a>
              </div>
              <ul className='social'>
                <li>
                  <a href='https://www.facebook.com/itsightpe' target="_blank"><IconFb/></a>
                </li>
                <li>
                  <a href='https://www.linkedin.com/company/itsight-consulting' target="_blank"><IconIn/></a>
                </li>
                <li>
                  <a href='https://www.instagram.com/itsightpe/' target="_blank"><IconIg/></a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <span className='page-header-background' 
          style={{transform: 'translate(0px, 0px)',}}
          >
          <span className='page-header-background-inner' 
            style={{
              backgroundColor: getColor(index), 
              transformOrigin: '50%' +`${directionScroll ? 100 : 0}`+'%',
              transform: counterCurrentScroll>0? 'scale(1,'+`${1+(-counterCurrentScroll/10)}`+')' : 'scale(1,'+`${1-(-counterCurrentScroll/10)}`+')',
              transition: isValidTransition ? '.08s all linear' : 'none'
            }} />
        </span>
      </div>
    );
}

export default Home;