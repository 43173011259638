import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {Device} from '../../assets/constans/constans'
import { withTranslation } from 'react-i18next';
import {activeAnimation} from '../../assets/js/keyframes';

class Clients extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     autoplay: {
  //       delay: 5000,
  //     },
  //   }
  // }
  isResponsive = () => {
    return window.innerWidth > Device.MOBILE ? true : false
  }
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const {t, i18n} = this.props;
    const aboutClients = t('aboutClients', { returnObjects: true });
    return (
      <section className='clients-container'>
      <Container className='client-container-wrapper'>
        <Row>
          <Col xl={7} md={8} className='ml-custom'>
            <h2>
              <span className='text-line'>{aboutClients.title1}</span>
              <span className='text-line'>{aboutClients.title2}</span>
            </h2>
            <p className='animationShow animationText'>
              <span>{aboutClients.text1}</span>
              <span>{aboutClients.text2}</span>  
            </p>
          </Col>
        </Row>
        <Swiper 
            slidesPerView={this.isResponsive()?4:2}
            className="mySwiper clients"
            autoplay={{ delay: 3000 }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_bcp.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_mibanco.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_wu.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_promperu.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_barrik.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_crossland.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_dollarhouse.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_efe.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_efectiva.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_englishadvance.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_grupoefe.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_lacuracao.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_maestro.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_mef.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_motocorp.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_osce.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_poderjudicial.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_promart.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_sodimax.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_tottus.png')} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={require('../../assets/img/clients/logo_wunderman.png')} />
            </SwiperSlide>
          </Swiper>
      </Container>
    </section>
    )
  }
}
export default withTranslation()(Clients)