import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import {activeAnimation} from '../../assets/js/keyframes'
import HowSteps from './how-steps';
import Partners from './partners';
import Carousel from './carousel';
import Fields from './fields';
import Clients from './clients';
import {Device} from '../../assets/constans/constans'
import { withTranslation } from 'react-i18next';
import { IconArrowBlueAnimation } from '../../assets/icons/index';

class About extends Component {
  constructor(props) {
    super(props);
    this.getTime = null;
    this.state = {
      
    };
  }
  isResponsive = () => {
    return window.innerWidth > Device.MOBILE ? true : false
  }
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const {t, i18n} = this.props;
    const aboutBanner = t('aboutBanner', { returnObjects: true });
    return(
      <>
        <div className='page-header'>
          <Container>
            <div className='page-header-wrapper'>
              <Row className='text-wrapper'>
                  <Col md={5} className='left-col'>
                    <div className='content-arrow-blue animationShow scaleCreated'>
                      <IconArrowBlueAnimation/>
                    </div>
                    <h1 className='animationShow slideRight' dangerouslySetInnerHTML={{__html: aboutBanner.titleSlider}}>
                      
                    </h1>
                  </Col>
                <Col md={7} className='right-col'>
                  { this.isResponsive() ?
                    <h2 className='animationShow animationText'>
                      <span>{aboutBanner.headerBanner1}</span>
                      <span>{aboutBanner.headerBanner2}</span>
                      <span>{aboutBanner.headerBanner3}</span>
                    </h2>
                    :
                    <h2 className='animationShow animationText'>
                      <span>{aboutBanner.headerBanner1}</span>
                      <span>{aboutBanner.headerBanner2}</span>
                      <span>{aboutBanner.headerBanner3}</span>
                      {/* <span>We design</span>
                      <span>solutions that ensure a</span>
                      <span>competitive advantage</span>
                      <span>for our clients.</span> */}
                    </h2>
                  }
                </Col>
              </Row>
              { this.isResponsive() ?        
                <div className='image-wrapper animationShow slideTop'>
                  <img src={require('../../assets/img/banner_about.png')} />
                </div>
                :
                ''
              }     
            </div>
          </Container>
          { this.isResponsive() ?   
          ''     
            :
            <div className='image-wrapper animationShow slideTop'>
            <img src={require('../../assets/img/banner_about.png')} />
          </div>
          }    
        </div>
        <div className='page-content'>
          <section className='what-container'>
            <Container>
              <div className='what-container-wrapper'>
                <Row>
                  <Col md={6} xs={12} className="what-title-wrapper ">
                    <p dangerouslySetInnerHTML={{__html: aboutBanner.title}}></p>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className='what-fields-wrapper'>
                      <span>{aboutBanner.titleFeature1}</span>
                      <p>{aboutBanner.features1}</p>
                    </div>
                    <div className='what-fields-wrapper'>
                      <span>{aboutBanner.titleFeature2}</span>
                      <p>{aboutBanner.features2}</p>
                    </div>
                    <div className='what-fields-wrapper'>
                      <span>{aboutBanner.titleFeature3}</span>
                      <p>{aboutBanner.features3}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <HowSteps />
          <Partners />
          <Carousel />
          <Fields />
          <Clients />
        </div>
      </>
    )
  }
}

export default withTranslation()(About);