import React, { Component } from 'react'
import {Row, Col, Tab, Nav, Container} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import {activeAnimation} from '../../assets/js/keyframes'
import { IconAdministracion,IconMigracion,IconDevOps,IconSoporte,IconWeb,IconMobile,
  IconComercio,IconApis,IconCloud,IconNoCode,IconData,IconHack,IconDevSecOps,IconAsesoria,
  IconBiometria,IconFirma,IconReputacion,IconRPA  } from '../../assets/icons/index';

class pageContent extends Component {
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const {t, i18n} = this.props;
    const workPage = t('workPage', { returnObjects: true });
    console.log('PROPS',workPage)
    return (
        <section className='work-list-container'>
          <Tab.Container defaultActiveKey="1" transition={true}>
            <Nav variant="pills" className='work-buttons'>
              <Nav.Item>
                <Nav.Link eventKey="1">{workPage.buttonCloud}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2">{workPage.buttonSoftware}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="3">{workPage.buttonCyber}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="4">{workPage.buttonDigital}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Container>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <h1>{workPage.cloudTitle}</h1>
                  <p>{workPage.cloudText}</p>
                  <Row className='items_container'>
                    <Col md={3} xs={6} className='item'>
                      <IconAdministracion />
                      <span>{workPage.cloudOption1}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconMigracion />
                      <span>{workPage.cloudOption2}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconDevOps />
                      <span>{workPage.cloudOption3}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconSoporte />
                      <span>{workPage.cloudOption4}</span>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <h1>{workPage.softwareTitle}</h1>
                  <p>{workPage.softwareText}</p>
                  <Row className='items_container'>
                    <Col md={3} xs={6} className='item'>
                      <IconWeb />
                      <span>{workPage.softwareOption1}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconMobile />
                      <span>{workPage.softwareOption2}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconComercio />
                      <span>{workPage.softwareOption3}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconApis />
                      <span>{workPage.softwareOption4}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconCloud />
                      <span>{workPage.softwareOption5}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconRPA />
                      <span>{workPage.softwareOption6}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconNoCode />
                      <span>{workPage.softwareOption7}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconData />
                      <span>{workPage.softwareOption8}</span>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <h1>{workPage.ciberTitle}</h1>
                  <p>{workPage.ciberText}</p>
                  <Row className='items_container'>
                    <Col md={3} xs={6} className='item'>
                      <IconHack />
                      <span>{workPage.ciberOption1}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconHack />
                      <span>{workPage.ciberOption2}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconDevSecOps />
                      <span>{workPage.ciberOption3}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconAsesoria />
                      <span>{workPage.ciberOption4}</span>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <h1>{workPage.digitalTitle}</h1>
                  <p>{workPage.digitalText}</p>
                  <Row className='items_container'>
                    <Col md={3} xs={6} className='item'>
                      <IconBiometria />
                      <span>{workPage.digitalOption1}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconFirma />
                      <span>{workPage.digitalOption2}</span>
                    </Col>
                    <Col md={3} xs={6} className='item'>
                      <IconReputacion />
                      <span>{workPage.digitalOption3}</span>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Container>
          </Tab.Container>
        </section>
    )
  }
}

export default withTranslation()(pageContent)