import React from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col,Button } from 'react-bootstrap';
import { IconFb, IconIg, IconIn } from '../../assets/icons/index';
import { withTranslation } from 'react-i18next';


const infoFooter=[
  {key:1,path:'/services',text:'Get to know our work',title:'About us',button:'Work', color:'#004f9f' },
  {key:2,path:'/lest-talk',text:'Let us know what you need',title:'Lest talk',button:'Lest talk', color:'#0068b6'},
  {key:3,path:'/about',text:'Our culture',title:'Work',button:'About', color:'#003979'}
]

// const ButtonNext = (props) => {
//   let navigate = useNavigate()
//   return (
//     <Button variant="outline-secondary" onClick={() => navigate(props.path)}>{props.text}</Button>
//   )
// }

class Footer extends React.Component {
  constructor(props){
    super(props);
  }

  keyFooter = () => {
    switch(window.location.pathname ) {
      case '/about' :
        return 2
      case '/services' :
        return 3
      case '/lest-talk' :
        return 1
      default :
        return 2
    }
  }

  render() {
    const {t, i18n,animationChangePage} = this.props;
    const footer = t('footer', { returnObjects: true });
    const correo = t('correo', { returnObjects: true });
    return (
      <footer className='page-footer'>
        <div className='page-footer-wrapper'>
          <Container>
            {/* {console.log(this.state.filterInfoFooter)} */}
            {
              footer.views.filter(itemFilter=> itemFilter.key===this.keyFooter()).map((item) =>(
                <>
                  <div className='content-dinamic-info'>
                    <div className='title-container'>
                      <a className='page-title-link main-link'>
                        <div className='next-page-title'>{item.key}.<span>{item.title}</span></div>
                      </a>
                      <a className='main-link'>
                        <div className='punch-line'>{item.text}</div>
                      </a>
                    </div>
                    <div className='content-button-work'>
                      <Button variant="outline-secondary" onClick={()=> animationChangePage(item.path,item.color)}>
                        {item.button}
                      </Button>
                    </div>
                  </div>
                </>
              ))
              //  <>
              //     <div className='title-container'>
              //       <a className='page-title-link main-link'>
              //         <div className='next-page-title'>{item.key}<span>{item.title}</span></div>
              //       </a>
              //       <a className='main-link'>
              //         <div className='punch-line'>{item.text}</div>
              //       </a>
              //     </div>
              //     <div className='content-button-work'>
              //       <Button variant="outline-secondary">{item.button}</Button>
              //     </div>
              //   </>
              }
            {/* } */}

            <div className='page-footer-links'>
              <a href='https://blog.itsight.pe' target="_blank" className='option-blog'>{footer.blog}</a>
              <ul className='social'>
                <li>
                  <a href='https://www.facebook.com/itsightpe' target="_blank"><IconFb/></a>
                </li>
                <li>
                  <a href='https://www.linkedin.com/company/itsight-consulting' target="_blank"><IconIn/></a>
                </li>
                <li>
                  <a href='https://www.instagram.com/itsightpe/' target="_blank"><IconIg/></a>
                </li>
              </ul>
              <a className='email-wrapper' href={'mailto:'+correo.correo}>{correo.correo}</a>
            </div>
          </Container>
        </div>
      </footer>
    );
  }
}

export default withTranslation() (Footer)