import React, { Component } from 'react';
import { Routes, Route, useLocation, Link } from "react-router-dom";

import Layout from './layouts/default';
import Home from './views/home/index';
import About from './views/about/index';
import Services from './views/services/index';
import LestTalk from './views/lest-talk/index';

function App(props) {
  
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/lest-talk" element={<LestTalk />} />
        {/* <Route path="about" element={<About />} /> */}
      </Route>
    </Routes>
  );
}

export default App;
