import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Device} from '../../assets/constans/constans';
import { withTranslation } from 'react-i18next';
import {activeAnimation} from '../../assets/js/keyframes';

class Fields extends Component  {
   isResponsive = () => {
    return window.innerWidth > Device.MOBILE ? true : false
  }
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const {t, i18n} = this.props;
    const aboutFields = t('aboutFields', { returnObjects: true });
    return (
      <div>
        <section className='fields-container'>
            <Container className='fields-container-wrapper'>
              <Row>
                <Col xl={7} md={8} className={this.isResponsive() ? 'ml-custom' : ''} style={{paddingLeft:this.isResponsive() ? '' : 0}}>
                  <h2 className='title-section animationShow animationText' dangerouslySetInnerHTML={{__html: aboutFields.title}}>
                    {/* <span className='text-line'>We adapt to you</span>
                    <span className='text-line'>from the product </span>
                    <span className='text-line'>specification</span> */}
                  </h2>
                </Col>
              </Row>
              <h3 dangerouslySetInnerHTML={{__html: aboutFields.sub_title}}></h3>
              <Row className='fields-items-wrapper'>
                <Col xl={3} sm={6} className='field-container one'>
                  <div className='field-wrapper'>
                    <img src={require('../../assets/img/field-1.png')} />
                    <div className='text-container'>
                      <h4>{aboutFields.business}</h4>
                      <p>{aboutFields.businessText}</p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6} className='field-container two'>
                  <div className='field-wrapper'>
                    <img src={require('../../assets/img/field-2.png')} />
                    <div className='text-container'>
                      <h4>{aboutFields.software}</h4>
                      <p>{aboutFields.softwareText}</p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6} className='field-container three'>
                  <div className='field-wrapper'>
                    <img src={require('../../assets/img/field-3.png')} />
                    <div className='text-container'>
                      <h4>{aboutFields.agile}</h4>
                      <p>{aboutFields.agileText}</p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6} className='field-container four'>
                  <div className='field-wrapper'>
                    <img src={require('../../assets/img/field-4.png')} />
                    <div className='text-container'>
                      <h4>{aboutFields.projects}</h4>
                      <p>{aboutFields.projectsText}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>      
      </div>
    )
  }
}
export default withTranslation() (Fields)