import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {Device} from '../../assets/constans/constans';
import { withTranslation } from 'react-i18next';
import {activeAnimation} from '../../assets/js/keyframes';

class Partners extends Component {
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const {t, i18n} = this.props;
    const aboutPartners = t('aboutPartners', { returnObjects: true });
    function isResponsive(){
      return window.innerWidth > Device.MOBILE ? true : false
    }

    return (
      <section className='tools-container'>
        <Container className='tools-container-wrapper'>
          <Row>
            <Col xl={8} md={8} className={isResponsive() ? 'ml-custom' : ''} >
              {isResponsive() ? 
              <h2 className='animationShow animationText' dangerouslySetInnerHTML={{__html: aboutPartners.title_dt}}>
                {/* <span className='text-line'>We are partners of </span>
                <span className='text-line'>world-class technology </span>
                <span className='text-line'>providers</span> */}
              </h2>
              :
              <h2 className='animationShow animationText' dangerouslySetInnerHTML={{__html: aboutPartners.title_mb}}>
                {/* <span className='text-line'>We are partners</span>
                <span className='text-line'>of world-class</span>
                <span className='text-line'>technology providers</span> */}
              </h2>
              }
            </Col>
          </Row>
          <ul className='animationShow slideIn'>
            <li>
              <img src={require('../../assets/img/partnert-1.png')} />
            </li>
            <li>
              <img src={require('../../assets/img/partnert-2.png')} />
            </li>
            <li>
              <img src={require('../../assets/img/partnert-3.png')} />
            </li>
            <li>
              <img src={require('../../assets/img/partnert-4.png')} />
            </li>
          </ul>
        </Container>
      </section>
    )
  }
}
export default withTranslation() (Partners)