import React from 'react';
import { IconLogo} from '../assets/icons/index';
import Spinner from '../assets/img/spinner.png'

class Loading extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div id='loading'>
        <IconLogo/>
        <img src={Spinner} />
      </div>
    );
  }
}

export default Loading