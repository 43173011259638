import React, { Component } from 'react'
import { Container, Row, Col, Tab  } from 'react-bootstrap';
import PageContent from './page-content';
import {activeAnimation} from '../../assets/js/keyframes';
import {Device} from '../../assets/constans/constans';
import { IconArrowBlueAnimation } from '../../assets/icons/index';
import { withTranslation } from 'react-i18next';

class Services extends Component {
  isResponsive = () => {
    return window.innerWidth > Device.MOBILE ? true : false
  }
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const {t, i18n} = this.props;
    const work = t('work', { returnObjects: true });
    return (
      <>
        <div className='page-header' style={{overflow:'hidden'}}>
          <Container>
            <div className='page-header-wrapper'>
              <Row className='text-wrapper'>
                  <Col md={5} className='left-col'>
                    <div className='content-arrow-blue animationShow scaleCreated'>
                      <IconArrowBlueAnimation/>
                    </div>
                    <h1 className='animationShow slideRight' dangerouslySetInnerHTML={{__html: work.titleSlider}}>
                    </h1>
                  </Col>
                <Col md={7} className='right-col'>
                  {this.isResponsive() ? 
                    <h2 className='animationShow animationText' style={{marginBottom:'5%'}} dangerouslySetInnerHTML={{__html: work.title_dt}}>
                    </h2>
                    :
                    <h2 className='animationShow animationText' style={{marginBottom:'5%'}} dangerouslySetInnerHTML={{__html: work.title_mb}}>
                    </h2>
                  }
                  {this.isResponsive() ?
                    <h3 className='animationShow animationText' dangerouslySetInnerHTML={{__html: work.text_dt}}>
                    </h3>
                    :
                    <h3 className='animationShow animationText' dangerouslySetInnerHTML={{__html: work.text_mb}}>
                    </h3>
                  }
                </Col>
              </Row>
            </div>
          </Container>
          {/* <div className='work-buttons'>
            <button className='btn btn-outline-white'>UX/UI Design</button>
            <button className='btn btn-outline-white'>Software Development</button>
            <button className='btn btn-outline-white'>Cybersecurity</button>
            <button className='btn btn-outline-white'>Digital identify</button>
          </div>     */}
        </div>
        <div className='page-content'>
          <PageContent/>
        </div>
      </>
    )
  }
}
export default withTranslation()(Services)