import React from 'react';
import { Link } from "react-router-dom";
import { Dropdown, Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import {activeAnimation} from '../../assets/js/keyframes'
import { IconLogo,IconCloseMobile,IconEllipsis } from '../../assets/icons/index';
import Loading from '../../components/loading';
import {Device} from '../../assets/constans/constans'
import { propTypes } from 'prop-types';
import { useState } from "react";

class Navbar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      collapseNavbar:false,
      lang: 'es',
      loading: false
    };
  }
  componentDidMount(){
    activeAnimation()
  }
  isResponsive = () => {
    return window.innerWidth > Device.MOBILE ? true : false
  }
  backgroundColapse = ()=> {
    switch(window.location.pathname ) {
      case '/about' :
        return' about'
      case '/services' :
        return ' services'
      case '/lest-talk' :
        return ' lest-talk'
      default:
        return 'home'
    }
  }
  activeOptionNavbar = (pathname) => {
    if(window.location.pathname === pathname){
      return 'active'
    }
  }
  handleChange = (i18n,value) => {
    this.setState({loading:true})
    if(value==0){
      i18n.changeLanguage("en")
    }else {
      i18n.changeLanguage("es")
    }
    window.location.reload();
  }
  render() { 
    const {collapseNavbar,loading} = this.state;
    const {positionSCroll,changeLocation, t, i18n} = this.props;
    const navbar = t('navbar', { returnObjects: true });
    const correo = t('correo', { returnObjects: true });
    const footer = t('footer', { returnObjects: true });

    const changeLocationDesktop = (location,color) =>{
      if(window.location.pathname== location) return
      changeLocation(location,color)
    }

    const showColapseNavbar = (value,location,color) =>{
      if(location !==null){changeLocation(location,color)}
      let elementNavbarPadre = document.querySelector('.main-menu-wrapper .responsive')
      let elementNavbar = document.querySelector('.main-menu-wrapper .responsive .optionsDropdownMobile')
      // value ? elementNavbarPadre.classList.toggle('active') : setTimeout(()=>{elementNavbarPadre.classList.toggle('active')}, 700)
      if(value){
        elementNavbar.classList.add('active')
        elementNavbar.classList.remove('close-active')
        elementNavbarPadre.classList.add('active')
      }else{
        elementNavbar.classList.remove('active')
        elementNavbar.classList.add('close-active')
        setTimeout(()=>{elementNavbarPadre.classList.remove('active')}, 1000)
      }
      value ? this.setState({collapseNavbar:value}) : setTimeout(()=>{this.setState({collapseNavbar:value})},800)
      // setTimeout(()=>{this.setState({collapseNavbar:value})},2000)
    }
    return (
      <div className={'main-menu-wrapper ' +  (positionSCroll ? 'activeNavbar' : '') + (collapseNavbar ? ' expanded ' : ' noExpanded ') + ( this.backgroundColapse())}>
        <span className='background'></span>
        {loading ? <Loading /> : null}
        <Container>
          <Row>
            <Col xs={12} md={7} className='content-logo-wrapper'>
              <div className='logo-wrapper' onClick={()=>changeLocationDesktop('/','#003979')}>
                <IconLogo/>
              </div>
              <div onClick={!collapseNavbar ? ()=> showColapseNavbar(true,null) : ()=> showColapseNavbar(false,null) }>
                {
                  !collapseNavbar ? <IconEllipsis className='ellipsis' /> : <IconCloseMobile className='icon-close'/>
                }
              </div>
            </Col>
            <Col xs={3} md={5} className='responsive'>
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <img src={require('../../assets/img/idioma-'+`${i18n.language}`+'.png')} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => {this.handleChange(i18n,0)}}>{navbar.en}</Dropdown.Item>
                  <Dropdown.Item onClick={() => {this.handleChange(i18n,1)}}>{navbar.es}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <ul className='menu-items animationShow raiseTextNavbar'>
                <li><span className={this.activeOptionNavbar('/about')} onClick={()=> changeLocationDesktop('/about','#003979')}>{navbar.about}</span></li>
                <li><span className={this.activeOptionNavbar('/services')} onClick={()=> changeLocationDesktop('/services','#004f9f')}>{navbar.work}</span></li>
                <li><span className={this.activeOptionNavbar('/lest-talk')} onClick={()=> changeLocationDesktop('/lest-talk','#0068b6')}>{navbar.lets_talk}</span></li>
              </ul>
              {this.isResponsive() ?
                ''
                :
                <div className={'optionsDropdownMobile responsiveDropdown '+ (this.backgroundColapse())}>
                  <div className='collapseHeader'>
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <img src={require('../../assets/img/idioma-'+`${i18n.language}`+'.png')} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {this.handleChange(i18n,0)}}>{navbar.en}</Dropdown.Item>
                        <Dropdown.Item onClick={() => {this.handleChange(i18n,1)}}>{navbar.es}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <ul>
                    <li><span className={this.activeOptionNavbar('/about')} onClick={()=> showColapseNavbar(false,'/about','#003979')}>{navbar.about}</span></li>
                    <li><span className={this.activeOptionNavbar('/services')} onClick={()=> showColapseNavbar(false,'/services','#004f9f')}>{navbar.work}</span></li>
                    <li><span className={this.activeOptionNavbar('/lest-talk')} onClick={()=> showColapseNavbar(false,'/lest-talk','#0068b6')}>{navbar.lets_talk}</span></li>
                  </ul>
                  <div className='footerDropdownLenguague'>
                    <a>{footer.blog}</a>
                    <a>{correo.correo}</a>
                  </div>
                </div>
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Navbar)