import React, {Component} from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import {Device} from '../../assets/constans/constans';
import { withTranslation } from 'react-i18next';
import {activeAnimation} from '../../assets/js/keyframes';

class HowSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      oldKey:1
    };
  }
  
  isResponsive = () => {
    return window.innerWidth > Device.MOBILE ? true : false
  }
  addClassNameAnimation = (k) =>{
    if(!this.isResponsive()){
      this.setState({key:k})
      return;
    }
    if(this.state.oldKey === k) return;
    let activeStepOld = document.querySelector('.how-steps-description-content .itemStep_'+this.state.oldKey);
    let counterchange = document.querySelector('.how-steps-description .counter')
        activeStepOld.classList.remove('active') 
        activeStepOld.classList.add('animationToglee')
        counterchange.classList.add('active')
      setTimeout(()=>{ 
        counterchange.classList.remove('active')
      }, 700)
      setTimeout(()=>{ 
        this.setState({key:k})
      }, 800)
      this.setState({oldKey:k})
  }
  componentDidMount(){
    activeAnimation()
  }
  render() {
    const { key } = this.state;
    const {t, i18n} = this.props;
    const aboutSteps = t('aboutSteps', { returnObjects: true });
    return (
      <section className='how-container'>
          <div className='how-container-wrapper'>
            <Container>
              {
                this.isResponsive() ?
                <h2 className='ml-custom' dangerouslySetInnerHTML={{__html: aboutSteps.title}}>
                </h2>
                :
                <h2 className='ml-custom' dangerouslySetInnerHTML={{__html: aboutSteps.title}}></h2>
                // <h2>
                //   Our values push us to<br/>
                //   always seek closeness<br/>                       
                //   and <span>transparency</span><br/>
                //   in all our activities
                // </h2>
              }
              <div className='how-steps-wrapper'>
                <Tab.Container activeKey={key} onSelect={(k)=>this.addClassNameAnimation(k)} transition={false}>
                  <Row>
                    <Col xl={3} md={4} className='how-steps-container ml-custom'>
                      <div className='how-steps-list'>
                        <div className='how-steps-list-inner'>
                          <h3 dangerouslySetInnerHTML={{__html: aboutSteps.titleDiferent}}></h3>

                          <Nav className="flex-column">
                          {
                            aboutSteps.steps.map((item,index) =>(
                              <>
                                <Nav.Item>
                                  <Nav.Link eventKey={item.key} key={index}>
                                    <div className='label'>
                                      <span className='background' />
                                      <span className='text'>{item.title}</span>
                                    </div>
                                    {this.isResponsive() ?
                                    ''
                                    :
                                    <div className='mobile-content'>
                                      <h4>{item.title}</h4>
                                      <p>
                                        {item.description}
                                      </p>
                                    </div>
                                    }
                                  </Nav.Link>
                                </Nav.Item>
                              </>
                            ))
                          }
                          </Nav>
                        </div>
                      </div>
                    </Col>
                    {this.isResponsive() ?
                      <Col lg={6} className='how-steps-description'>
                        <Row>
                          <Col xs={3}>
                            <span className='counter'><span>{key}</span>/{aboutSteps.steps.length}</span>
                          </Col>
                          <Col xs={9} className='how-steps-description-content' style={{maxWidth: 720}}>
                            <Tab.Content>
                            {
                              aboutSteps.steps.map((item,index) =>(
                                <>
                                <Tab.Pane eventKey={item.key} key={index} className={('itemStep_'+(index+1))}>
                                  <h4>{item.title}</h4>
                                  <p>{item.description}</p>
                                </Tab.Pane>
                                </>
                              ))
                            }
                            </Tab.Content>
                          </Col>
                        </Row>
                        
                      </Col>
                      :
                      null
                    }
                  </Row>
                </Tab.Container>
              </div>
            </Container>
          </div>
        </section>
    )
  }
}
export default withTranslation() (HowSteps)