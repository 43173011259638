let es = {
  translations: {
    correo: {
      "correo":"hello@itsight.pe"
    },
    navbar: {
      "about": "Nosotros",
      "work": "Servicios",
      "lets_talk": "Contacto",
      "en": "Inglés",
      "es": "Español"
    },
    home:{
      "step1": "<span>Hola! Nosotros somos <strong>ITSight</strong></span><span>Un equipo de profesionales enfocados en brindar soluciones</span><span>tecnológicas eficientes</span><span>Conoce más <div id='description_1' style='display: inline-flex;' class='textLink'>sobre nosotros</span></span>",
      "step2": "<span>Amamos la tecnología</span><span>Planificamos tu idea, la elaboramos y priorizamos contigo el</span><span>mayor valor agregado</span><span>Nos aseguramos de que los resultados sean los esperados</span><span>Desplegamos tu idea y analizas los resultados!</span><span>Conoce más sobre <div id='description_2' style='display: inline-flex;' class='textLink'>nuestro servicio.</div></div>",
      "step3": "<span>Estamos ubicados en Lima, Perú</span><span>Ofrecemos servicios de manera global</span><span>Si deseas que te ayudemos, solo ponte en contacto con nosotros</span><span>¿Estás listo?, <div id='description_3' style='display: inline-flex;' class='textLink'>Conversemos!</span></span>"
    },
    aboutBanner:{
      "headerBanner1": "Diseñamos soluciones que",
      "headerBanner2": "aseguran una ventaja",
      "headerBanner3": "competitiva para nuestros clientes.",
      "titleSlider": "<span class='text'>N</span><span class='text'>o</span><span class='text'>s</span><span class='text'>o</span><span class='text'>t</span><span class='text'>r</span><span class='text'>o</span><span class='text'>s</span>",
      "title": "Somos un equipo de profesionales que apuesta por la comunicación fluida, la  transparencia en todos nuestros procesos y el compromiso de mejora continua en todas nuestras actividades.",
      "titleFeature1": "Quién",
      "titleFeature2": "Cómo",
      "titleFeature3": "Misión",
      "features1": "¿Quiénes somos? Somos un grupo de profesionales que llevamos más de 8 años diseñando soluciones tecnológicas. Estamos ubicados en Lima, Perú, pero tenemos clientes en el extranjero.",
      "features2": "¿Cómo lo hacemos? Nuestro proceso se basa en un flujo constante de comunicación entre nuestro equipo y clientes, manteniendo siempre unas expectativas claras y un objetivo totalmente definido. Nuestro cliente es el foco principal de todas nuestras actividades.",
      "features3": "¿Nuestra misión? Ayudar a las empresas a crear soluciones innovadoras, eficientes y transparentes en una relación de plena colaboración."
    },
    aboutSteps:{
      "title":"Nuestros valores nos empujan a buscar siempre la cercanía y <span>transparencia</span> en todas nuestras actividades",
      "titleDiferent": "¿Qué nos hace <span>diferentes?</span>",
      steps: [
        {key: 1, title: 'Innovación', description: 'Búsqueda continua en la creación de nuevos productos, servicios y en la mejora continua de nuestro trabajo. Por ello fomentamos la creatividad, investigación y el desarrollo constante de estas iniciativas.'},
        {key: 2, title: 'Integridad', description: 'El comportamiento de todos nuestros integrantes se basa en los principios de ética, responsabilidad, respeto, honestidad y honradez. Tenemos el compromiso de hacer siempre lo correcto.'},
        {key: 3, title: 'Excelencia', description: 'Se busca calidad en todo lo que hacemos con el fin de asegurar la satisfacción del cliente. Nos desafiamos a nosotros mismos para optimizar nuestro trabajo, planteando metas concretas que buscamos superar.'},
        {key: 4, title: 'Emprendimiento', description: "Actuar como emprendedores en la propuesta de nuevos proyectos y en el desarrollo de los mismos para impulsar el crecimiento de la empresa. Ser proactivo, perseverante y tener iniciativa permite agregar valor y rentabilidad a nuestra empresa."},
      ]
    },
    aboutPartners:{
      "title_dt": "<span class='text-line'>Somos socios de negocio</span><span class='text-line'>de proveedores de tecnología </span><span class='text-line'>de clase mundial</span>",
      "title_mb": "<span class='text-line'>Somos socios de</span><span class='text-line'>negocio de proveedores de</span><span class='text-line'>tecnología de clase mundial</span>"
    },
    aboutFields:{
      "title": "<span class='text-line'>Nos adaptamos a tus</span><span class='text-line'>necesidades, desde  </span><span class='text-line'>el inicio y siempre</span>",
      "sub_title": "Trabajamos contigo desde los primeros conceptos de diseño, el lanzamiento del producto y su futuro mantenimiento",
      "business": "Análisis de requerimientos",
      "businessText":"Ayudamos a nuestros clientes a visualizar los productos que necesitan a través de un buen servicio de asesoramiento y priorización.",
      "software": "Ingeniería de software",
      "softwareText": "Nuestro equipo diseña e implementa soluciones de software utilizando tecnologías modernas.",
      "agile": "Equipos ágiles",
      "agileText": "Hacemos que tus soluciones cobren vida de forma más ágil y rápida, priorizando la generación de valor.",
      "projects": "Proyectos de inicio a fin",
      "projectsText": "Vemos cada proyecto desde su concepción hasta su finalización.",
    },
    aboutClients:{
      "title1": "Nuestros clientes confirman ",
      "title2": "nuestro trabajo",
      "text1": "Tenemos claro que el éxito de nuestra compañía está estrechamente ligado  a la confianza que nos tienen nuestro clientes.",
      "text2": ""
    },
    work:{
      "titleSlider":"<span class='text'>S</span><span class='text'>e</span><span class='text'>r</span><span class='text'>v</span><span class='text'>i</span><span class='text'>c</span><span class='text'>i</span><span class='text'>o</span><span class='text'>s</span>",
      "title_dt": "<span>Queremos ayudarte en la creación de tu solución,</span><span>estamos capacitados para ello.</span>",
      "title_mb": "<span>Queremos ayudarte en la creación</span><span>de tu solución, estamos</span><span>capacitados para ello.</span>",
      "text_dt": "<span class='text-word'>Las necesidades del mercado cada vez son más exigentes, por lo que</span><span class='text-word'>estar alineado a este ritmo tan cambiante requiere de un</span><span class='text-word'>cambio de paradigma. Conoce todo lo que hacemos.</span>",
      "text_mb": "<span class='text-word'>Las necesidades del mercado cada vez son más exigentes, por</span><span class='text-word'>lo que estar alineado a este ritmo tan cambiante requiere </span><span class='text-word'>de un cambio de paradigma. Conoce todo lo que hacemos.</span>"
    },
    workPage:{
      "buttonCloud": "Cloud",
      "buttonSoftware": "Desarrollo de software",
      "buttonCyber": "Ciberseguridad",
      "buttonDigital": "Identidad digital",
      "buttonTransform": "Transformación organizacional",
      "cloudTitle": "Cloud",
      "cloudText": "Guiamos a tu compañía diseñando la hoja de ruta hacia los servicios implementados.",
      "cloudOption1": "Servicios administrados",
      "cloudOption2": "Migración",
      "cloudOption3": "DevOps",
      "cloudOption4": "Soporte",
      "softwareTitle": "Desarrollo de software",
      "softwareText": "Diseñamos, construimos y desplegamos soluciones escalables por ti.",
      "softwareOption1": "Aplicaciones web",
      "softwareOption2": "Aplicaciones móviles",
      "softwareOption3": "Comercio electrónico",
      "softwareOption4": "APIs",
      "softwareOption5": "Desarrollo cloud",
      "softwareOption6": "RPA",
      "softwareOption7": "No code/Low code",
      "softwareOption8": "Data",
      "ciberTitle": "Ciberseguridad",
      "ciberText": "Te ayudamos a crear productos protegidos desde su creación hasta el monitoreo.",
      "ciberOption1": "Hacking continuo",
      "ciberOption2": "Hacking puntual",
      "ciberOption3": "DevSecOps",
      "ciberOption4": "Asesoría",
      "digitalTitle": "Identidad digital",
      "digitalText": "Diseñamos soluciones “paperless” que permitan flujos de trabajo seguros y verificables",
      "digitalOption1": "Biometría",
      "digitalOption2": "Firma electrónica y digital",
      "digitalOption3": "Reputación"
    },
    lestTalk:{
      "titleSlider" :"<span class='text'>C</span><span class='text'>o</span><span class='text'>n</span><span class='text'>t</span><span class='text'>a</span><span class='text'>c</span><span class='text'>t</span><span class='text'>o</span>",
      "title_mb": "<span>Somos el hogar de profesionales</span><span>creativos, estrategas</span><span> e innovadores.</span><span>Podemos ayudarte a conseguir todos tus objetivos.</span>",
      "title_dt": "<span>Somos el hogar de profesionales creativos,</span><span>estrategas e innovadores. </span><span>Podemos ayudarte a conseguir todos tus objetivos.</span>",
      "correo": "Para consultas generales, déjanos un <br/> mensaje al siguiente correo electrónico: <br/> hello@itsight.pe. Con gusto <br/> nos pondremos en contacto contigo."
    },
    footer: {
      "blog": "Conoce nuestro blog",
      views: [
        {key:2, path:'/services',text:'Conoce nuestros servicios',title:'Servicios',button:'Servicios', color:'#004f9f'},
        {key:3, path:'/lest-talk',text:'Háganos saber lo que necesita',title:'Contacto',button:'Contacto', color:'#0068b6'},
        {key:1, path:'/about',text:'Nuestra cultura',title:'Nosotros',button:'Nosotros',color:'#003979'}
      ]
    }
  }
};

export default es;