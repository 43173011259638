import React, { useEffect,useCallback } from 'react';
import { useLocation, Outlet,useNavigate } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import {activeAnimation} from '../assets/js/keyframes'
import Navbar from '../components/global/navbar';
import Footer from '../components/global/footer';
// import {Device} from '../../assets/constans/constans';
function Layout(props) {
    const location = useLocation();
    const [statePositionSCroll,setStatePositionSCroll] = React.useState(false);
    const [propValueFooter,setPropValueFooter] = React.useState(0);
    const [counterWheel,setCounterWheel] = React.useState(0);
    const [isActiveWheel,setIsActiveWheel] = React.useState(false);
    const navigate = useNavigate();
    const [permissionChangeLocation, setPermissionChangeLocation] = React.useState(true);
    const scrollbarRef = React.useRef();
    function isResponsive(){
        return window.innerWidth > 768 ? true : false
    }
    useEffect(()=>{
        const interval = setInterval(() => {
            if(isActiveWheel && counterWheel>=10){
                dinamicFooterClass()
            }
          }, 3000);
          return () => clearInterval(interval);
    })
    
  useEffect(()=>{
    let mouseEvent = document.querySelectorAll('.textLink')
    mouseEvent.forEach((item)=>{
      if(item.id== 'description_1'){
        document.getElementById(item.id).addEventListener('click',()=>animationChangePage('/about','#003979'))
      }else if(item.id== 'description_2'){
        document.getElementById(item.id).addEventListener('click',()=>animationChangePage('/services','#004f9f'))
      }else{
        document.getElementById(item.id).addEventListener('click',()=>animationChangePage('/lest-talk','#0068b6'))
      }
    })
  })
    
    function classWrapper(path) {
        switch (path) {
          case "/" :
            return "home";
          case "/about":
            return "about";
        case "/services":
            return "services";
        case "/lest-talk":
            return "lest-talk";
          default:
            return "";
        }
    }
    function locationNext(){
        // debugger;
        switch (window.location.pathname) {
            case "/" :
              return "home";
            case "/about":
              return "services";
            case "/services":
                return "lest-talk";
            case "/lest-talk":
                return "/about";
            default:
              return "";
          }
    }
    function startScroll(e){
        e.scrollTop ==0 ? setStatePositionSCroll(false) : setStatePositionSCroll(true)
        footerChangePage(e.top)
    }
    function footerChangePage(stateScroll){
        if(stateScroll < 1) return;
        setIsActiveWheel(true)
        setPropValueFooter(1)
    }
    var footerContainer = document.querySelector('.page-footer .page-footer-wrapper .container');
    var scrollBarElement = document.querySelector('.views-container div:first-child');
    var pageContent = document.querySelector('.views-container .page-content');
    var pageHeader = document.querySelector('.views-container .page-header');
    function wheelChange(deltaY){
        if(!isResponsive()) return
        if(!isActiveWheel) return;
        if(deltaY>0){
            if(counterWheel<17){
                setCounterWheel(counterWheel+1)
                // debugger;
            }else return
        }else{
            setCounterWheel(counterWheel-1)
            if(counterWheel <=0){
                scrollBarElement.style.overflow='scroll';
                scrollBarElement.style.paddingRight='0'
                pageContent.style.transform = 'translateY(0vh)';
                pageHeader.style.transform = 'translateY(0vh)';
                setIsActiveWheel(false)
                return
            }
            if(counterWheel>9){
                dinamicFooterClass()
                return;
            }
        }
        setPropValueFooter(counterWheel)
        if(counterWheel!=8){
            pageContent.style.transform = 'translateY('+(-counterWheel*6.25)+'vh)';
            pageHeader.style.transform = 'translateY('+(-counterWheel*6.25)+'vh)';
            if(counterWheel> 8){
                footerContainer.style.opacity =(1 - (counterWheel* 0.0625));
                footerContainer.style.height = (counterWheel*6.25)+'vh';
                footerContainer.style.marginTop= (100 - (counterWheel*6.25))+'vh';
            }
        }
        if(counterWheel>-9 && counterWheel<71){
            if(deltaY>0){
                scrollBarElement.style.overflow='scroll';
                scrollBarElement.style.paddingRight='0';
            }else{
                scrollBarElement.style.overflow='hidden';
                // debugger;
                if(isResponsive()){
                    scrollBarElement.style.paddingRight='17px'
                } 
            }
        }else {
            scrollBarElement.style.overflow='scroll';
            scrollBarElement.style.paddingRight='0';
            return
        }
        if(counterWheel=== 16){
            setIsActiveWheel(true)
            setTimeout(()=>{
                navigate(locationNext())
                scrollbarRef.current.scrollTop(0)
                dinamicFooterClass()
                setIsActiveWheel(false)
            },1000)
        }
    }
    function dinamicFooterClass(){
        setCounterWheel(8)
        pageContent.style.transform = 'translateY(-60vh)';
        pageHeader.style.transform = 'translateY(-60vh)';
        footerContainer.style.opacity=1;
        footerContainer.style.height ='60vh';
        footerContainer.style.marginTop = '40vh';
        scrollBarElement.style.paddingRight='0';
        scrollBarElement.style.overflow='scroll';
    }

    function changeLocation(location,color){
        if(!permissionChangeLocation) return
        setPermissionChangeLocation(false)
        let elementNavbar = document.querySelector('.main-menu-wrapper .responsive .optionsDropdownMobile')
        if(elementNavbar)elementNavbar.classList.add('close-active')
        setTimeout(()=>{ 
            animationChangePage(location,color)
        }, 800)
    }

    function animationChangePage(location,color){
        // debugger;
        let divAnimation = document.querySelector('.divAnimationChangePage')
        for(let i = 0;i<=16;i++){
            setTimeout(()=>{
                divAnimation.style.opacity=1
                divAnimation.style.height = (i*6.25)+'vh';
                divAnimation.style.background = color;
                if(i===16){
                    setTimeout(()=>{
                        setStatePositionSCroll(false)
                        if(location== '/'){
                            navigate(location)
                            return
                        }
                        navigate(location)
                    }, 8 * 16)
                    
                }
            }, 16 * i)
        }
        setTimeout(()=>{
            // debugger;
            if(scrollBarElement){
                scrollBarElement.style.overflow='scroll';
                scrollBarElement.style.paddingRight='0';
            }
            divAnimation.style.opacity=0;
            divAnimation.style.height = '0vh';
            scrollbarRef.current.scrollTop(0);
            setIsActiveWheel(false)
            setPermissionChangeLocation(true)
        },40*20)
    }

    return(
        <>
            <div id='wrapper' className={classWrapper(location.pathname)}>
                <Navbar className='prueba' positionSCroll={statePositionSCroll} changeLocation={ (location,color) => changeLocation(location,color)}/>
                <Scrollbars ref={scrollbarRef} onWheel={(a) => wheelChange(a.deltaY)} className='views-container' style={{position:'fixed'}} onScrollFrame={(e) => startScroll(e)} onScroll={(e) =>activeAnimation(e)}>
                    <Outlet />
                    { location.pathname!= '/' ?
                        <Footer valuePositionFooter={propValueFooter} animationChangePage={(location,color)=> animationChangePage(location,color)}/> : null                        
                    }
                </Scrollbars>
                <div className='divAnimationChangePage'></div>
            </div>
        </>
    )
}

export default Layout;