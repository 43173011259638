export function activeAnimation(DELAY){
    // console.log(DELAY ,'delayyy inicial')
    //animationShow se utliza para saber que es una animación -----------------------------------
    let animation = document.querySelectorAll('.animationShow');
    animation.forEach((e) =>{
        let heightWindow = window.innerHeight/1;
        let positionObj = e.getBoundingClientRect().top;
        if(positionObj < heightWindow){
            // if(e.classList.contains('animationTextHome')) animationTextHome(e,DELAY);
            if(e.classList.contains('animationText')) isAnimationText(e,DELAY);
            if(e.classList.contains('raiseTextNavbar')) isRaiseTextNavbar(e);
            if(e.classList.contains('slideIn')) isSlideIn(e);
            if(e.classList.contains('scaleCreated')) isScaleCreated(e);
            if(e.classList.contains('slideTop')) isSlideTop(e);
            if(e.classList.contains('slideTopHome')) isSlideTopHome(e);
            if(e.classList.contains('slideRight')) slideRight(e,DELAY);
        }
        //las clases(estilos) es para entender que tipo de animación se deberia dar al elemento-------
    })
}
function animationTextHome(e,DELAY) {
    let children =Array.prototype.slice.call(e.children);
    let childrenChildrenH1 = Array.prototype.slice.call(children[1].children);
    let timeChangeSpan = (20000/childrenChildrenH1.length);
    let totalSpan = document.querySelectorAll('.main-slider h1 span')
    // setTimeout(()=>{ 
        children[0].style.animation = '1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter'
        children[0].style.opacity = 1
    // }, 400)
    childrenChildrenH1.forEach((a,index)=>{
        const timeAnimationHome = setTimeout(()=>{ 
            totalSpan.forEach(element => {
                element.classList.remove('active');
                element.style.animation='1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter_back';
                element.style.opacity = 0
            });
            if(e.classList.contains('animationShow')){
                a.style.animation = '1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter'
                a.style.opacity = 1;
                // a.style.visibility='inherit'
                if(index>0){
                    childrenChildrenH1[index-1].classList.remove('active');
                    childrenChildrenH1[index-1].style.animation='1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter_back';
                    childrenChildrenH1[index-1].style.opacity = 0;
                }
                if(index==childrenChildrenH1.length-1){
                    // setTimeout(()=>{
                        a.classList.remove('active')
                    // },timeChangeSpan*index)
                }
                a.classList.add('active')
                a.style.animation = '1s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter';
                a.style.opacity = 1;
            }else{
                clearTimeout(timeAnimationHome);
            }
        }, index==0 ? '' : timeChangeSpan*index)
    })
    validStatusRepeatAnimation(e,childrenChildrenH1,DELAY)

}
function isAnimationText(e,DELAY) {
    // debugger;
    let children =Array.prototype.slice.call(e.children)
    children.forEach((a,index)=>{
        setTimeout(()=>{ 
            a.style.animation = '2s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards line_enter'
            a.style.opacity = 1
        }, 400 * index)
    })
    //eliminar los settimeout al terminar
    //sintaxis sacada de acá 
    //https://developer.mozilla.org/es/docs/Web/CSS/animation
    //statusRepeat es para saber si la animación se va a retirar una vez se acabe su animación para volver a activarse
    validStatusRepeatAnimation(e,children,DELAY)

}

function isRaiseTextNavbar(e){
    let children =Array.prototype.slice.call(e.children)
    children.forEach((a,index)=>{
        setTimeout(()=>{ 
            a.style.animation = 'slide-in-bottom 1s cubic-bezier(0.785, 0.135, 0.150, 0.860) 0s forwards'
            a.style.opacity = 1
        }, 450 * index)
    })
    validStatusRepeatAnimation(e,children)
}

function isSlideIn(e){
    let children =Array.prototype.slice.call(e.children)
    children.forEach((a,index)=>{
        setTimeout(()=>{ 
            a.style.animation = 'slide-in-bottom 1.2s cubic-bezier(0.680, -0.550, 0.265, 1.550) 0s forwards'
            a.style.opacity = 1
        }, 500 * index)
    })
    // validStatusRepeatAnimation(e,children)
}

function isScaleCreated(e){
    let children =Array.prototype.slice.call(e.children)
    children.forEach((a,index)=>{
        setTimeout(()=>{ 
            a.style.animation = 'scale-created 2s ease-in 1s forwards'
            a.style.opacity = 1
        }, 500 * index)
    })
    validStatusRepeatAnimation(e,children)
}

function isSlideTop(e){
    let children =Array.prototype.slice.call(e.children)
    children.forEach((a,index)=>{
        setTimeout(()=>{ 
            a.style.animation = 'slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards'
            a.style.opacity = 1
        }, 800 * index)
    })
    validStatusRepeatAnimation(e,children)
}

function isSlideTopHome(e){
    let children =Array.prototype.slice.call(e.children)
    children.forEach((a,index)=>{
        setTimeout(()=>{ 
            a.style.animation = 'slide-top .8s linear forwards';
            a.style.opacity = 1;
        }, 800 * index)
    })
    validStatusRepeatAnimation(e,children)
}

function slideRight(e,DELAY) {
    let children =Array.prototype.slice.call(e.children)
    children.forEach((a,index)=>{
        setTimeout(()=>{ 
            a.style.animation = '2s cubic-bezier(0.430, 0.195, 0.020, 1.000) 0s forwards slideRight'
            a.style.opacity = 1
        }, 200 * index)
    })
    validStatusRepeatAnimation(e,children,DELAY)

}

function validStatusRepeatAnimation(e,children,DELAY){
    if(DELAY == undefined) DELAY = 15000
    if(e.classList.contains('statusRepeat')){
        children.forEach(b =>{
            setTimeout(function(){
                b.style.animation=''
                b.style.opacity = ''
            }, DELAY)
        })
    }
}