let en = {
    translations: {
      correo: {
        "correo":"hello@itsight.pe"
      },
      navbar: {
        "about": "About us",
        "work": "Our service",
        "lets_talk": "Lets talk",
        "en": "English",
        "es": "Spanish"
      },
      home:{
        "step1": "<span>Hi! We are <strong>ITSight</strong></span><span>A team of professionals focused on providing efficient teachnological solutions</span><span>Learn more <div id='description_1' style='display: inline-flex;' class='textLink'>about us</span></span>",
        "step2": "<span>We are passionate about technology.</span><span>We plan your idea, develop it and prioritize the highest added value with your input.</span><span>We ensure that the results obtained are those expected.</span><span>We deliver your idea, and you analyze the results!</span><span>Learn more about <div id='description_2' style='display: inline-flex;' class='textLink'>our service.</span></span>",
        "step3": "<span>We are based in Lima, Peru</span><span>We offer comprehensive services.</span><span>If you would like our support, contact us.</span><span>Are you ready?, <div id='description_3' style='display: inline-flex;' class='textLink'>Let's talk!</span></span>"
      },
      aboutBanner:{
        "headerBanner1": "We design solutions that",
        "headerBanner2": "ensure a competitive",
        "headerBanner3": "advantage for our clients.",
        "titleSlider": "<span class='text'>A</span><span class='text'>b</span><span class='text'>o</span><span class='text'>u</span><span class='text'>t</span><span class='text' style='width:4%'> </span><span class='text'>u</span><span class='text'>s</span>",
        "title": "We are a team of professionals committed to fluid communication, transparency in all our processes, and a compromise for continuous improvement in all our business activities.",
        "titleFeature1": "WHO",
        "titleFeature2": "HOW",
        "titleFeature3": "MISSION",
        "features1": "Who are we? We are a team of professionals who have been designing technological solutions for over eight years. We are based in Lima, Peru, but serving clients abroad.",
        "features2": "How do we do it? We base our process on a constant flow of communication between our team and our clients, always keeping clear expectations and a fully defined objective. Our clients are the main focus of all our activities.",
        "features3": "Our mission? To help companies create innovative, efficient, and transparent solutions in a fully collaborative relationship."
      },
      aboutSteps:{
        "title": "Our values drive us to always strive towards a close and <span>transparent </span> relationship in all our activities.",
        "titleDiferent": "What makes us <span>different?</span>",
        steps: [
          {key: 1, title: 'Innovation', description: 'We are in continuous pursuit of the creation of new products and services and the constant improvement of our work. For this reason, we encourage creativity, research, and the continual development of these initiatives.'},
          {key: 2, title: 'Integrity', description: 'The professionalism of all our members is based on the principles of ethics, responsibility, respect, and honesty. . We are devoted to always acting with integrity.'},
          {key: 3, title: 'Excellence', description: 'We strive for quality in everything we do to ensure customer satisfaction. We challenge ourselves to optimize our work, setting concrete goals we seek to exceed.'},
          {key: 4, title: 'Entrepreneurship', description: "We act as entrepreneurs by proposing new projects and their development to promote the company's growth. Being proactive, persevering, and taking the initiative allows us to add value and profitability to our company."},
        ]
      },
      aboutPartners:{
        "title_dt":"<span class='text-line'>We are business partners of </span><span class='text-line'>world-class technology </span><span class='text-line'>providers.</span>",
        "title_mb":"<span class='text-line'>We are business partners of</span><span class='text-line'>world-class</span><span class='text-line'>technology providers.</span>"
      },
      aboutFields:{
        "title":"<span class='text-line'>We adapt to your needs,</span><span class='text-line'>from the beginning </span><span class='text-line'>and forward.</span>",
        "sub_title": "We work with you from the first design concepts, product launch, and future maintenance.",
        "business": "Requirements Analysis",
        "businessText": "We help our customers to visualize the products they need through expert consulting and prioritization.",
        "software": "Software Engineering",
        "softwareText": "Our team designs and implements software solutions using cutting-edge technologies.",
        "agile": "Agile Teams",
        "agileText": "We make your solutions come to life faster and quicker, prioritizing the generation of value.",
        "projects": "End-to-End Projects",
        "projectsText": "We see each project from its conception to its completion.",
      },
      aboutClients:{
        "title1": "Our clients support ",
        "title2": "our work",
        "text1": "We understand that client trust is critical to our company's success.",
        "text2": ""
      },
      work:{
        "titleSlider":"<span class='text'>O</span><span class='text'>u</span><span class='text'>r</span><span class='text' style='width:4%'></span><span class='text'>W</span><span class='text'>o</span><span class='text'>r</span><span class='text'>k</span>",
        "title_dt": "<span>We are qualified and eager to assist you</span><span>in developing your solution.</span>",
        "title_mb": "<span>We are qualified and eager</span><span> to assist you in</span><span>developing your solution.</span>",
        "text_dt": "<span class='text-word'>A paradigm shift is necessary to adapt to the</span><span class='text-word'>escalating demands of the market.</span><span class='text-word'>Learn more about what we do.</span>",
        "text_mb": "<span class='text-word'>A paradigm shift is necessary to adapt to </span><span class='text-word'>the escalating demands of the market. </span><span class='text-word'>Learn more about what we do</span>"
      },
      workPage:{
        "buttonCloud": "Cloud",
        "buttonSoftware": "Software Development",
        "buttonCyber": "Cybersecurity",
        "buttonDigital": "Digital identify",
        "buttonTransform": "Organizational Transformation",
        "cloudTitle": "Cloud",
        "cloudText": "We assist your business in creating the road plan for implementing services.​",
        "cloudOption1": "Managed Services",
        "cloudOption2": "Migration",
        "cloudOption3": "DevOps",
        "cloudOption4": "Support​",
        "softwareTitle": "Software Development",
        "softwareText": "We design, build and deploy scalable solutions for your business.",
        "softwareOption1": "Web Applications",
        "softwareOption2": "Mobile Applications​",
        "softwareOption3": "E-commerce​",
        "softwareOption4": "APIs​",
        "softwareOption5": "Cloud Development​",
        "softwareOption6": "RPA​",
        "softwareOption7": "No code/Low code​",
        "softwareOption8": "Data​",
        "ciberTitle": "Cybersecurity​",
        "ciberText": "We help you create protected products from creation to monitoring.",
        "ciberOption1": "Continuous Hacking​",
        "ciberOption2": "Punctual Hacking​",
        "ciberOption3": "DevSecOps​",
        "ciberOption4": "Consulting​",
        "digitalTitle": "Digital Identity​",
        "digitalText": "We design paperless solutions that enable secure and verifiable workflows.",
        "digitalOption1": "Biometry​",
        "digitalOption2": "Electronic and digital signature​",
        "digitalOption3": "Reputation​"
      },
      lestTalk:{
        "titleSlider" :"<span class='text'>C</span><span class='text'>o</span><span class='text'>n</span><span class='text'>t</span><span class='text'>a</span><span class='text'>c</span><span class='text'>t</span>",
        "title_mb": "<span>We are home</span><span>to creative</span><span>professionals,</span><span>strategists, and</span><span>innovators. We can</span><span>help you achieve all </span><span>your goals.</span>",
        "title_dt": "<span>We are home to creative</span><span>professionals, strategists,</span><span>and innovators. We can</span><span>help you achieve all your goals.​</span>",
        "correo": "For general inquiries, drop us a <br/>line at the following email: <br/> hello@itsight.pe We will be happy <br/> to contact you."
      },
      footer: {
        "blog": "Meet our blog",
        views: [
          {key:2, path:'/services',text:'Get to know our services',title:'Our service',button:'Our service', color:'#004f9f'},
          {key:3, path:'/lest-talk',text:'Let us know what you',title:'Lest talk',button:'Lest talk', color:'#0068b6'},
          {key:1, path:'/about',text:'Our culture',title:'About us',button:'About us', color:'#003979'}
        ]
      }
    }
  };
  
  export default en;